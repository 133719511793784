import { Card, message, Select } from 'antd';
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { createDistrict, updateDistrict, getDistrictDetails } from '../apis/districts';
import { getAllProvinces } from '../apis/provinces';
import Overlay from '../components/Overlay';
import { getError, sluggify } from '../utility';

function AddUpdateDistrict() {
    const queryClient = useQueryClient();
    const { slug } = useParams();
    const is_add = (slug == null);
    const [district, setDistrict] = useState({
        "name": "",
        "slug": "",
        "province": "",
    })
    const [provinces, setProvinces] = useState([]);
    const navigate = useNavigate();

    const { mutate: createDistrictMutate, isLoading: isCreating } = useMutation(() => createDistrict({district}), {
        onSuccess: (data) => {
            message.success("District created successfully!");
            queryClient.invalidateQueries("get-districts");
            navigate("/districts/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })

    const { mutate: updateDistrictMutate, isLoading: isUpdating } = useMutation(() => updateDistrict({slug, district}), {
        onSuccess: (data) => {
            message.success("District updated successfully!");
            queryClient.invalidateQueries("get-districts");
            navigate("/districts/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })

    const checkAndCreate = async (e) => {
        e.preventDefault();
        if (!(district.name && district.slug)) {
            return message.error("Please provide all the required fields!");
        }
        if (is_add) createDistrictMutate();
        else updateDistrictMutate();
    };

    const { mutate: getDistrictDetailsMutate, isLoading: isGettingDistrictDetails } = useMutation(() => getDistrictDetails({slug}), {
        onSuccess: (data) => {
            setDistrict({...data.data.data, province: data.data.data.province.id});
        },
        onError: (error) => message.error(getError(error))
    })

    const { isLoading } = useQuery(
        "get-all-provinces",
        () => getAllProvinces(),
        {
          onSuccess: (data) => {
            setProvinces(data.data.data);
          },
          onError: (error) => message.error(getError(error)),
          refetchInterval: 5 * 1000 * 60,
        }
      )

    useEffect(() => {
        if (!is_add) getDistrictDetailsMutate({slug});
      }, [slug])
        
  return (
    <>
        <Overlay to="/districts/" extraClass="" />
        <div className='pop-model add-district-model'>
            <h2 className='title'>{is_add ? "Add District" : "Update District"}</h2>
            <Card loading={isLoading || isCreating || isUpdating || isGettingDistrictDetails} className='container'>
                <form onSubmit={checkAndCreate}>
                    <div className='input-container'>
                        <label htmlFor="name">District Name <span className='asterik'>*</span></label>
                        <input type={"text"} placeholder="Kathmandu" id="name" value={district.name} onChange={(e) => setDistrict({...district, name: e.target.value, slug: sluggify(e.target.value)})} />
                    </div>
                    <div className='input-container'>
                        <label htmlFor="slug">District Slug <span className='asterik'>*</span></label>
                        <input type={"text"} placeholder="kathmandu" id="slug" value={district.slug} onChange={(e) => setDistrict({...district, slug: e.target.value})} />
                    </div>
                    <div className='input-container'>
                        <label htmlFor="province">Province <span className='asterik'>*</span></label>
                        <Select
                        id="province"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Select a Province"
                        defaultValue={district.province}
                        onChange={(value) => setDistrict({...district, province: value})}
                        options={provinces.map(province => ({label: province.name, value: province.id}))}
                        />
                    </div>
                    <div className='button-container'>
                        <button className='button button-primary' type='submit'>{is_add ? "Create" : "Update"}</button>
                    </div>
                </form>
            </Card>
        </div>
    </>
  )
}

export default AddUpdateDistrict