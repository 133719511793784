import React from 'react';
import Home from './pages/Home';
import Places from './pages/Places';
import Activities from './pages/Activities';
import AppLayout from './components/AppLayout';
import NoPage from './pages/NoPage';
import Login from "./pages/Login";
import Messages from './pages/Messages';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthProvider from "./AuthProvider";
import OnlyAnonymous from "./OnlyAnonymous";
import RequireAuth from "./RequireAuth";
import AdminContextProvider from "./AdminContextProvider";


import 'antd/dist/reset.css';
import Users from './pages/Users';
import AddUpdateUser from './pages/AddUpdateUser';
import Provinces from './pages/Provinces';
import AddUpdateProvince from './pages/AddUpdateProvince'
import Districts from './pages/Districts';
import AddUpdateDistrict from './pages/AddUpdateDistrict';
import Cities from './pages/Cities';
import AddUpdateCity from './pages/AddUpdateCity';
import AddUpdatePlaces from './pages/AddUpdatePlaces';
import ResetUserPassword from './pages/ResetUserPassword';
import ContactMessages from './pages/ContactMessages';


const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route element={
              <OnlyAnonymous>
                <Outlet />
              </OnlyAnonymous>
            }
            path="/login"
            >
              <Route element={<Login />} index />
            </Route>

            <Route element={
              <RequireAuth>
                <AdminContextProvider>
                  <AppLayout />
                </AdminContextProvider>
              </RequireAuth>
              }
              path="/"
            >
              <Route index element={<Home />} />
              <Route path='provinces' element={
                <>
                <Outlet />
                <Provinces />
                </>
              }>
                <Route index element={<></>} />
                <Route path="add" element={<AddUpdateProvince />} />
                <Route path=":slug" element={<AddUpdateProvince />} />
              </Route>
              <Route path='districts' element={
                <>
                <Outlet />
                <Districts />
                </>
              }>
                <Route index element={<></>} />
                <Route path="add" element={<AddUpdateDistrict />} />
                <Route path=":slug" element={<AddUpdateDistrict />} />
              </Route>
              <Route path='local-levels' element={
                <>
                <Outlet />
                <Cities />
                </>
              }>
                <Route index element={<></>} />
                <Route path="add" element={<AddUpdateCity />} />
                <Route path=":slug" element={<AddUpdateCity />} />
              </Route>
              <Route path='sensors' element={
                <>
                <Outlet />
                <Places />
                </>
              }>
                <Route index element={<></>} />
                <Route path="add" element={<AddUpdatePlaces />} />
                <Route path=":slug" element={<AddUpdatePlaces />} />
              </Route>
              <Route path='users' element={
                <>
                <Outlet />
                <Users />
                </>
              }>
                <Route index element={<></>} />
                <Route path='add' element={<AddUpdateUser />} />
                <Route path=':id/reset-password' exact element={<ResetUserPassword />} />
                <Route path=':id' element={<AddUpdateUser />} />
              </Route>
              <Route path='activities' element={<Activities />} />
              <Route path='messages' element={<Messages />} />
              <Route path='contact-messages' element={<ContactMessages />} />
            </Route>
            <Route path='*' element={<NoPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
