import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const RequireAuth = ({children}) => {
    const auth = useAuth();
    const location = useLocation();
    if (!auth?.loggedUser) {
        return <Navigate state={{from: location}} to="/login/" replace />;
    }
    return children;
}
export default RequireAuth;