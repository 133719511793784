import { Footer } from 'antd/es/layout/layout'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import Sidebar from './Sidebar'


const AppLayout = () => {
  return (
    <div className="App">
        <Navbar />
        <Sidebar />
        <Outlet />
        <Footer
            style={{
                textAlign: 'center',
                marginBottom: 0,
            }}
            className="footer"
        >
            <p className="copy-text">Copyright © 2022, <span className="bold">RecentAir | Heisenberg Engineering Private Limited</span>. All Rights Reserved.</p>
        </Footer>
    </div>
  )
}

export default AppLayout