import {
    LinkOutlined,
    LoginOutlined,
    HomeOutlined,
  } from '@ant-design/icons';
import {AiOutlineUser} from "react-icons/ai";
import {FiActivity} from "react-icons/fi";
import {CiLocationOn} from "react-icons/ci";
import {BiMessageSquareDetail, BiCurrentLocation} from "react-icons/bi";
import { Menu } from 'antd';
import {Link, useLocation} from "react-router-dom";
import { useAuth } from '../AuthProvider';

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const items = [
    getItem(<Link to="/">Home</Link>, '0', <HomeOutlined />),
    getItem('Locations', 'locations', <BiCurrentLocation />, [
      getItem(<Link to="/provinces/">Provinces</Link>, '1', <CiLocationOn />),
      getItem(<Link to="/districts/">Districts</Link>, '2', <CiLocationOn />),
      getItem(<Link to="/local-levels/">Local Levels</Link>, '3', <CiLocationOn />),
      getItem(<Link to="/sensors/">Sensors</Link>, '4', <CiLocationOn />),
    ]),
    getItem(<Link to="/users/">Users</Link>, '5', <AiOutlineUser />),
    getItem(<Link to="/activities/">Activities</Link>, '6', <FiActivity />),
    getItem(<Link to="/messages/">Messages</Link>, '7', <BiMessageSquareDetail />),
    getItem(<Link to="/contact-messages/">Contact Messages</Link>, '8', <BiMessageSquareDetail />),
    getItem(
      <a href="https://recentair.com" target="_blank" rel="noopener noreferrer">
        View Website
      </a>,
      '9',
      <LinkOutlined />,
    ),
  ];
  const Sidebar = () => {
    const { logoutUserFromApi } = useAuth();
    let location = useLocation();
    const getSelectedKeys = () => {
      var activeKey;
      try {
        activeKey = items.find(item => location.pathname.split('/')[1] === item.label.props.to.split('/')[1]).key;
      } catch(e) {
        activeKey = null;
      }
      return [activeKey];
    }
    return (
      <>
        <Menu
          selectedKeys={getSelectedKeys()}
          mode={'inline'}
          items={items}
          className="sidebar"
          defaultOpenKeys={['locations']}
        />
        <div className='sidebar-fixed-bottom-container'>
          <button onClick={() => {
            if (window.confirm("Are you sure to Log Out?")) {
              logoutUserFromApi();
            }
          }}>
            <LoginOutlined /><span className="text">Log Out</span>
          </button>
        </div>
      </>
    );
  };
  export default Sidebar;