import React, { useState } from 'react'
import { MdOutlineAdd } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getClients, deleteClient } from "../apis/clients";
import { Spin, message, Table } from 'antd';
import { getError } from '../utility';

const Users = () => {
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 8,
  })
  // const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: '(Company)Name',
      dataIndex: 'user',
      render: (user, client) => <span className='name-container' style={{display: "flex", flexDirection: "column"}}>
          {client.organization ? <span className='org-name'>{client.organization}</span> : null}
          <Link className='name' to={`/users/${client.id}`}>{user.first_name} {user.last_name}</Link>
          <a className='email' href={`mailto:${user.email}`}>{user.email}</a>
        </span>
    },
    {
      title: 'Alloted',
      dataIndex: 'alloted',
      render: (alloted, client) => alloted.map((place, index) => <span key={place.id}><a href={`/places/${place.id}/`}>{place.name}</a>{alloted.length !== index+1 && ", "}</span>),
    },
    {
      title: "Phone No.:",
      dataIndex: "phone",
      render: (phone) => <a href={"tel:"+phone}>{phone}</a>
    },
    {
      title: 'Actions',
      dataIndex: 'allow_all',
      render: (allow_all, client) => {
        return (
          <Spin spinning={() => loadingClients.find(loadingClient => loadingClient === client.id) }>
            <label><input type={"checkbox"} checked={allow_all} onChange={
              async (e) =>{
                setLoadingClients([...loadingClients, client.id]);
                if (client.allow_all) {
                  await confirmAndUpdateClient({client, allow_all: false});
                } else {
                  await confirmAndUpdateClient({client, allow_all: true});
                }
                // setLoadingRooms(loadingRooms.filter(loadingRoom => loadingRoom === room.code));
              }
            } /> Allowed All</label>
          </Spin>
        )
      }
    },
    {
      title: "",
      dataIndex: "suspended",
      render: (suspended, client) => <Spin spinning={() => loadingClients.find(loadingClient => loadingClient === client.id) }>
      <label><input type={"checkbox"} checked={suspended} onChange={
        async (e) =>{
          setLoadingClients([...loadingClients, client.id]);
          if (client.suspended) {
            await confirmAndUpdateClient({client, suspended: false});
          } else {
            await confirmAndUpdateClient({client, suspended: true});
          }
          // setLoadingRooms(loadingRooms.filter(loadingRoom => loadingRoom === room.code));
        }
      } /> Suspended</label>
    </Spin>
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id) => <><button type='button' className='button button-danger' onClick={() => confirmAndDelete(id)}>Delete</button> <Link to={`${id}/reset-password`} className='button button-warning'>Reset Password</Link></>
    }
  ];
  const confirmAndDelete = (id) => {
    if (window.confirm(`Are you sure to delete the user with id "${id}"?\nEvery activities, records related with the main user would be gone.`)) deleteMutate({id});
  }
  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(({id}) => deleteClient({id}), {
    onSuccess: (data) => {
        message.success("User deleted successfully!");
        queryClient.invalidateQueries("get-clients");
    },
    onError: (error) => message.error(getError(error))
})
  const { isLoading, refetch, isFetching } = useQuery(
    "get-clients",
    () => getClients(pagination),
    {
      onSuccess: (data) => {
        console.log(data);
        setClients(data.data.results);
      },
      onError: (error) => console.log(error),
      refetchInterval: 5 * 1000 * 60,
    }
  )
  const confirmAndUpdateClient = () => {};
  return (
    <main className="main model-view room">
        <Header title="Users" />
        <div className='main-container'>
            <div className='actions-container'>
                <Link to="/users/add/" className='button button-regular'><MdOutlineAdd /> Add Users</Link>
            </div>
            <div className='table-container'>
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={clients}
              loading={isLoading || isFetching}
              pagination={{
                total: clients?.count,
                current: pagination.page,
                pageSize: pagination.pageSize,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} clients`,
                onChange: (page, pageSize) => setPagination({ page, pageSize }),
              }}
            />
            </div>
        </div>
    </main>
  )
}

export default Users