import axios from "../myaxios";
import { apiDomain } from "../constants"

export const getDistricts = ({ page = 1, pageSize = 8 }) => {
    return axios.get(`${apiDomain}admin/districts/?page=${page}&page_size=${pageSize}`);
}

export const getAllDistricts = () => {
    return axios.get(`${apiDomain}admin/all-districts/`);
}

export const updateDistrict = ({ slug, district }) => {
    return axios.put(`${apiDomain}admin/districts/${slug}/`, district);
}

export const getDistrictDetails = ({slug}) => {
    return axios.get(`${apiDomain}admin/districts/${slug}/`)
}

export const deleteDistrict = ({slug}) => {
    return axios.delete(`${apiDomain}admin/districts/${slug}/`)
}


export const createDistrict = ({ district }) => {
    return axios.post(`${apiDomain}admin/districts/`, district);
}