import axios from "../myaxios";
import { apiDomain } from "../constants"

export const getMessages = ({ page = 1, pageSize = 8 }) => {
    return axios.get(`${apiDomain}admin/message/?page=${page}&page_size=${pageSize}`);
}


export const deleteMessage = ({ id }) => {
    return axios.delete(`${apiDomain}admin/message/${id}/`);
}