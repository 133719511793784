import { Card, message } from 'antd';
import React, { useState } from 'react'
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../apis/clients';
import Overlay from '../components/Overlay';
import { getError } from '../utility';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function ResetUserPassword() {
    const { id } = useParams();
    const [inputType, setInputType] = useState("password");
    const [newPassword, setNewPassword] = useState("")
    const navigate = useNavigate();

    const { mutate: resetPasswordMutate, isLoading: isCreating } = useMutation(() => resetPassword({id, newPassword}), {
        onSuccess: (data) => {
            message.success("Password reset and mail sent successfully!");
            navigate("/users/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })


    const checkAndCreate = async (e) => {
        e.preventDefault();
        resetPasswordMutate();
    };

    const switchInput = (e) => {
        if (inputType === "password")
        setInputType("text");
        else setInputType("password");
    }

    const generateRandom = (e) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{};:|,.<>/?`~";
        let password = "";

        for (let i = 0; i < 16; i++) {
            password += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        setNewPassword(password);
    }
        
  return (
    <>
        <Overlay to="/users/" extraClass="" />
        <div className='pop-model reset-password-model'>
            <h2 className='title'>Reset Password</h2>
            <Card loading={isCreating} className='container'>
                <form onSubmit={checkAndCreate}>
                    <div className='input-container'>
                        <label htmlFor="password">New Password </label>
                        <input type={inputType} placeholder="" id="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        {newPassword.length > 0 && <button type='button' className='button button-simple' onClick={switchInput}>{inputType === "password" ? <AiFillEye /> : <AiFillEyeInvisible />}</button>}
                    </div>
                    <small className='button-container'>
                        <button className='button button-regular' type='button' onClick={generateRandom}>Generate random</button>
                    </small>
                    <div className='button-container'>
                        <button className='button button-primary' type='submit'>Save</button>
                    </div>
                    <small>
                        Leave the field empty to generate the random password at backend.<br /><br />
                        The new password would be automatically messaged to the user at his/her/their email address.
                    </small>
                </form>
            </Card>
        </div>
    </>
  )
}

export default ResetUserPassword