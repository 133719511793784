import axios from "../myaxios";
import { apiDomain } from "../constants";

const getEndUser = async () => {
    const data = await axios.get(`${apiDomain}admin/`);
    return data;
}

const getDownloadCertificatesUrl = ({slug}) => `${apiDomain}things/${slug}/download/cert/`;

export {getEndUser, getDownloadCertificatesUrl};