import { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import { getEndUser } from "./apis/admin";
import { useAuth } from "./AuthProvider";

let AdminContext = createContext(null);
export default function AdminContextProvider({ children }) {
    const [user, setUser] = useState();
    const [theme, setTheme] = useState('light');

    let { logoutUser } = useAuth();
    const { } = useQuery(
        ["get-end-user"],
        async () => getEndUser(),
        {
        retry: false,
        onError: (err) => {
            logoutUser();
        },
        onSuccess: (data) => {
            setUser(data.data)
        }
        }
    );

    const changeTheme = (value) => {
        setTheme(value ? 'dark' : 'light');
      };

    let value = {
        theme,
        changeTheme,
        user,
    }

    return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
}
function useAdminContext() {
    return useContext(AdminContext);
}

export { useAdminContext };