import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getError } from '../utility';
import { deleteProvince, getProvinces } from '../apis/provinces';
import { message, Table } from 'antd';
import { MdOutlineAdd } from 'react-icons/md';

const Provinces = () => {
    const [provinces, setProvinces] = useState();
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 8,
    })
    const queryClient = useQueryClient();
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (name, province) => <Link to={`/provinces/${province.slug}`}>{name}</Link>
      },
      {
        title: 'Slug',
        dataIndex: 'slug',
      },
      {
        title: 'Actions',
        dataIndex: 'slug',
        render: (slug) => <button type='button' className='button button-danger' onClick={() => confirmAndDelete(slug)}>Delete</button>
      }
    ];
    
    const confirmAndDelete = (slug) => {
      if (window.confirm(`Are you sure to delete the province with slug "${slug}"?\nNote that all of the districts, cities and places associated with it also will be deleted!`)) deleteMutate({slug});
    }

    const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(({slug}) => deleteProvince({slug}), {
      onSuccess: (data) => {
          message.success("Province deleted successfully!");
          queryClient.invalidateQueries("get-provinces");
      },
      onError: (error) => message.error(getError(error))
  })

    const { isLoading, refetch, isFetching } = useQuery(
      "get-provinces",
      () => getProvinces(pagination),
      {
        onSuccess: (data) => {
          setProvinces(data.data);
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
      }
    )
  
    useEffect(() => {
      refetch();
    }, [pagination.page, pagination.pageSize]);
  return (
    <main className="main model-view room">
      <Header title="Provinces" />
      <div className='main-container'>
        <div className='actions-container'>
          <Link to="/provinces/add/" className='button button-regular'><MdOutlineAdd /> Add Province</Link>
        </div>
        <div className='table-container'>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={provinces?.results}
            loading={isLoading || isFetching}
            pagination={{
              total: provinces?.count,
              current: pagination.page,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} provinces`,
              onChange: (page, pageSize) => setPagination({ page, pageSize }),
            }}
          />
        </div>
      </div>
    </main>
  )
}

export default Provinces