import axios from "../myaxios";
import { apiDomain } from "../constants"

export const getRequests = ({ page = 1, pageSize = 8 }) => {
    return axios.get(`${apiDomain}clients/request/?page=${page}&page_size=${pageSize}`);
}

export const getAllRequests = () => {
    return null;
}

export const updateRequest = ({ id, status }) => {
    return axios.put(`${apiDomain}clients/request/${id}/`, {status});
}


export const deleteRequest = ({ id }) => {
    return axios.delete(`${apiDomain}clients/request/${id}`);
}