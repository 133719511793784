import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getError } from '../utility';
import { deleteRequest, getRequests, updateRequest } from '../apis/messages';
import { message, Select, Table } from 'antd';

const Messages = () => {
    const [messages, setMessages] = useState();
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 8,
    })
    const queryClient = useQueryClient();
    const getOptions = (label, value) => {
      return {label, value};
    }
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Client',
        dataIndex: 'client',
        render: (client) => <Link to={`/users/${client.id}`}>{client.user.first_name} {client.user.last_name}</Link>,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
      },
      {
        title: 'Message',
        dataIndex: 'message',
        width: "20rem",
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status, request) => <Select
          allowClear
          style={{
              width: '100%',
          }}
          placeholder=""
          defaultValue={status}
          onChange={(value) => {
            // send direct axios req and invalidate queries
            updateMutate({id: request.id, status: value})
          }}
          options={[
            getOptions("Send", "0"),
            getOptions("Heard", "1"),
            getOptions("Accepted and Closed", "2"),
            getOptions("Declined and Closed", "3"),
          ]}
          />
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id) => <button type='button' className='button button-danger' onClick={() => confirmAndDelete(id)}>Delete</button>
      }
    ];
    
    const confirmAndDelete = (id) => {
      if (window.confirm(`Are you sure to delete Request Message #${id}?`)) deleteMutate({id});
    }

    const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(({id}) => deleteRequest({id}), {
      onSuccess: (data) => {
          message.success("Request Message deleted successfully!");
          queryClient.invalidateQueries("get-messages");
      },
      onError: (error) => message.error(getError(error))
  })

  const { mutate: updateMutate, isLoading: isUpdating } = useMutation(({id, status}) => updateRequest({id, status}), {
    onSuccess: (data) => {
        message.success("Request Message updated successfully!");
        queryClient.invalidateQueries("get-messages");
    },
    onError: (error) => message.error(getError(error))
})

    const { isLoading, refetch, isFetching } = useQuery(
      "get-messages",
      () => getRequests(pagination),
      {
        onSuccess: (data) => {
          setMessages(data.data);
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
      }
    )
  
    useEffect(() => {
      refetch();
    }, [pagination.page, pagination.pageSize]);
  return (
    <main className="main model-view room">
      <Header title="Messages" />
      <div className='main-container'>
        <div className='table-container'>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={messages?.results}
            loading={isLoading || isFetching}
            pagination={{
              total: messages?.count,
              current: pagination.page,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} messages`,
              onChange: (page, pageSize) => setPagination({ page, pageSize }),
            }}
          />
        </div>
      </div>
    </main>
  )
}

export default Messages