import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Overlay({to, extraClass}) {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    }
  }, [to]);
  const closeOverlay = () => {
      navigate(to, {replace: true});
  }
  return (
    <div className={`overlay ${extraClass}`} onClick={() => closeOverlay()} />
  )
}

export default Overlay