import axios from "../myaxios";
import { apiDomain } from "../constants"

export const getCities = ({ page = 1, pageSize = 8 }) => {
    return axios.get(`${apiDomain}admin/cities/?page=${page}&page_size=${pageSize}`);
}

export const getAllCities = () => {
    return axios.get(`${apiDomain}admin/all-cities/`);
}

export const updateCity = ({ slug, city }) => {
    return axios.put(`${apiDomain}admin/cities/${slug}/`, city);
}

export const getCityDetails = ({slug}) => {
    return axios.get(`${apiDomain}admin/cities/${slug}/`);
}

export const deleteCity = ({ slug }) => {
    return axios.delete(`${apiDomain}admin/cities/${slug}`);
}

export const createCity = ({ city }) => {
    return axios.post(`${apiDomain}admin/cities/`, city);
}