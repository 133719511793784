import React from 'react'
import Header from '../components/Header';

const Home = () => {
  return (
    <div className='main-container'>
      <Header title="Home" />
      <main className='main home'>
        <h2 className='sub-heading' style={{
          textAlign: "center"
        }}>Welcome, admin!</h2>
        <div className='imgae-container' style={{
          width: "100%",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <img src='/images/boy-hello.svg' alt='' style={{
            width: "18rem",
          }} />
        </div>
      </main>
    </div>
  )
}

export default Home