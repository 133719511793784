import { Card, message, Select } from 'antd';
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { createCity, updateCity, getCityDetails } from '../apis/cities';
import { getAllDistricts } from '../apis/districts';
import Overlay from '../components/Overlay';
import { getError, sluggify } from '../utility';

function AddUpdateLocalLevel() {
    const queryClient = useQueryClient();
    const { slug } = useParams();
    const is_add = (slug == null);
    const [localLevel, setLocalLevel] = useState({
        "name": "",
        "slug": "",
        "district": "",
    })
    const [districts, setDistricts] = useState([]);
    const navigate = useNavigate();

    const { mutate: createCityMutate, isLoading: isCreating } = useMutation(() => createCity({localLevel}), {
        onSuccess: (data) => {
            message.success("Local Level created successfully!");
            queryClient.invalidateQueries("get-local-levels");
            navigate("/local-levels/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })

    const { mutate: updateCityMutate, isLoading: isUpdating } = useMutation(() => updateCity({slug, localLevel}), {
        onSuccess: (data) => {
            message.success("Local Level updated successfully!");
            queryClient.invalidateQueries("get-local-levels");
            navigate("/local-levels/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })

    const checkAndCreate = async (e) => {
        e.preventDefault();
        if (!(localLevel.name && localLevel.slug)) {
            return message.error("Please provide all the required fields!");
        }
        if (is_add) createCityMutate();
        else updateCityMutate();
    };

    const { mutate: getCityDetailsMutate, isLoading: isGettingLocalLevelDetails } = useMutation(() => getCityDetails({slug}), {
        onSuccess: (data) => {
            setLocalLevel({...data.data.data, district: data.data.data.district.id});
        },
        onError: (error) => message.error(getError(error))
    })

    const { isLoading } = useQuery(
        "get-all-districts",
        () => getAllDistricts(),
        {
          onSuccess: (data) => {
            setDistricts(data.data.data);
          },
          onError: (error) => message.error(getError(error)),
          refetchInterval: 5 * 1000 * 60,
        }
      )

    useEffect(() => {
        if (!is_add) getCityDetailsMutate({slug});
      }, [slug])
        
  return (
    <>
        <Overlay to="/local-levels/" extraClass="" />
        <div className='pop-model add-localLevel-model'>
            <h2 className='title'>{is_add ? "Add Local Level" : "Update Local Level"}</h2>
            <Card loading={isLoading || isCreating || isUpdating || isGettingLocalLevelDetails} className='container'>
                <form onSubmit={checkAndCreate}>
                    <div className='input-container'>
                        <label htmlFor="name">Local Level Name <span className='asterik'>*</span></label>
                        <input type={"text"} placeholder="Koteshwor" id="name" value={localLevel.name} onChange={(e) => setLocalLevel({...localLevel, name: e.target.value, slug: sluggify(e.target.value)})} />
                    </div>
                    <div className='input-container'>
                        <label htmlFor="slug">Local Level Slug <span className='asterik'>*</span></label>
                        <input type={"text"} placeholder="koteshwor" id="slug" value={localLevel.slug} onChange={(e) => setLocalLevel({...localLevel, slug: e.target.value})} />
                    </div>
                    <div className='input-container'>
                        <label htmlFor="district">District <span className='asterik'>*</span></label>
                        <Select
                        id="district"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Select a District"
                        defaultValue={localLevel.district}
                        onChange={(value) => setLocalLevel({...localLevel, district: value})}
                        options={districts.map(district => ({label: district.name, value: district.id}))}
                        />
                    </div>
                    <div className='button-container'>
                        <button className='button button-primary' type='submit'>{is_add ? "Create" : "Update"}</button>
                    </div>
                </form>
            </Card>
        </div>
    </>
  )
}

export default AddUpdateLocalLevel