import axios from "../myaxios";
import { apiDomain } from "../constants"

export const getPlaces = ({ page = 1, pageSize = 8 }) => {
    return axios.get(`${apiDomain}admin/places/?page=${page}&page_size=${pageSize}`);
}

export const getAllPlaces = () => {
    return axios.get(`${apiDomain}admin/all-places/`);
}

export const updatePlace = ({ slug, place }) => {
    return axios.put(`${apiDomain}admin/places/${slug}/`, place);
}

export const createPlace = ({ place }) => {
    // return axios.post(`${apiDomain}admin/places/`, place);
    return axios.post(`${apiDomain}admin/things/`, place);
}

export const getPlaceDetails = ({slug}) => {
    return axios.get(`${apiDomain}admin/places/${slug}/`);
}

export const deletePlace = ({slug}) => {
    return axios.delete(`${apiDomain}admin/things/${slug}`);
}

export const gotoDownloadCertificate = ({slug, certificate_id}) => {
    return `${apiDomain}admin/things/${slug}/download/${certificate_id}/`;
}