import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const OnlyAnonymous = ({children}) => {
    const auth = useAuth();
    const location = useLocation();
    if (auth?.loggedUser) {
        return <Navigate to="/" replace />;
    }
    return children;
}
export default OnlyAnonymous;