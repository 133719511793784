import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getError } from '../utility';
import { getPlaces, deletePlace, getPlaceDetails, gotoDownloadCertificate, createPlace, updatePlace } from '../apis/places';
import { message, Table } from 'antd';
import { MdOutlineAdd } from 'react-icons/md';

const Places = () => {
    const [places, setPlaces] = useState();
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 8,
    })
    const queryClient = useQueryClient();

    const { mutate: updatePlaceStatusMutate, isLoading: isUpdatingStatus } = useMutation(({slug, off}) => {
      const temp_place = {
        off,
      }
      return updatePlace({slug, place: temp_place})
    }, {
      onSuccess: (data) => {
          message.success("Status updated successfully!");
          queryClient.invalidateQueries("get-places");
      },
      onError: (error) => message.error(getError(error))
  })

    const confirmAndUpdatePlace = async ({slug, off}) => {
      if (window.confirm(`Are you sure to update the status of Sensor "${slug}"?`)) {
        updatePlaceStatusMutate({slug, off});
      }
    }

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (name, place) => <Link to={`/sensors/${place.slug}`}>{name}</Link>
      },
      {
        title: 'City / District',
        dataIndex: 'city',
        render: (city) => `${city.name} / ${city.district.name}`,
      },
      {
        title: 'Lat/Lon',
        dataIndex: 'lat',
        render: (lat, place) => {return `${lat} / ${place.lon}`},
      },
      {
        title: 'Change status',
        dataIndex: 'off',
        render: (status, place) => {
          return (
              <label><input type={"checkbox"} checked={status} onChange={
                async (e) =>{
                  if (status) {
                    await confirmAndUpdatePlace({slug: place.slug, off: false});
                  } else {
                    await confirmAndUpdatePlace({slug: place.slug, off: true});
                  }
                }
              } /> Off</label>
          )
        }
      },
      {
        title: 'Actions',
        dataIndex: 'slug',
        render: (slug, place) => place.downloaded ? <div className='place-status-container'><img src="/images/icon-yes.svg" /> <span className="text">Downloaded</span></div> : <div className='table-buttons-container'>
            <a className='button button-primary' href={gotoDownloadCertificate({slug, certificate_id: place.certificate_id})} target="_blank">Download Certificates</a>
          </div>
      },
      {
        title: '',
        dataIndex: 'slug',
        render: (slug) => <button type='button' className='button button-danger' onClick={() => confirmAndDelete(slug)}>Delete</button>
      }
    ];

    const confirmAndDelete = (slug) => {
      if (window.confirm(`Are you sure to delete the Sensor with slug "${slug}"?`)) deleteMutate({slug});
    }

    const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(({slug}) => deletePlace({slug}), {
      onSuccess: (data) => {
          message.success("Sensor deleted successfully!");
          queryClient.invalidateQueries("get-places");
      },
      onError: (error) => message.error(getError(error))
  })

    const { isLoading, refetch, isFetching } = useQuery(
      "get-places",
      () => getPlaces(pagination),
      {
        onSuccess: (data) => {
          setPlaces(data.data);
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
      }
    )
  
    useEffect(() => {
      refetch();
    }, [pagination.page, pagination.pageSize]);
  return (
    <main className="main model-view room">
      <Header title="Sensors" />
      <div className='main-container'>
        <div className='actions-container'>
          <Link to="/sensors/add/" className='button button-regular'><MdOutlineAdd /> Add Sensor</Link>
        </div>
        <div className='table-container'>
          <Table
            columns={columns}
            rowKey={(record) => record.slug}
            dataSource={places?.results}
            loading={isLoading || isFetching}
            pagination={{
              total: places?.count,
              current: pagination.page,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} Sensors`,
              onChange: (page, pageSize) => setPagination({ page, pageSize }),
            }}
          />
        </div>
      </div>
    </main>
  )
}

export default Places