import axios from "../myaxios";
import { apiDomain } from "../constants";

const getClients = async () => {
    return await axios.get(`${apiDomain}clients/`);
}

const createClient = async ({client}) => {
    return await axios.post(`${apiDomain}clients/`, client);
}

const getClientDetails = async ({ id }) => {
    return await axios.get(`${apiDomain}clients/${id}/`);
}

const deleteClient = async ({id}) => {
    return await axios.delete(`${apiDomain}clients/${id}/`);
}

const updateClientDetails = async ({ id, client}) => {
    return await axios.put(`${apiDomain}clients/${id}/`, client);
}

const resetPassword = ({id, newPassword}) => {
    return axios.post(`${apiDomain}clients/${id}/reset-password/`, {new_password: newPassword});
}

export {getClients, createClient, getClientDetails, updateClientDetails, deleteClient, resetPassword};