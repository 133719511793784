import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getError } from '../utility';
import { getDistricts, deleteDistrict } from '../apis/districts';
import { message, Table } from 'antd';
import { MdOutlineAdd } from 'react-icons/md';

const Districts = () => {
    const [districts, setDistricts] = useState();
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 8,
    })
    const queryClient = useQueryClient();
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (name, district) => <Link to={`/districts/${district.slug}`}>{name}</Link>
      },
      {
        title: 'Slug',
        dataIndex: 'slug',
      },
      {
        title: 'Province',
        dataIndex: 'province',
        render: (province) => province.name,
      },
      {
        title: 'Actions',
        dataIndex: 'slug',
        render: (slug) => <button type='button' className='button button-danger' onClick={() => confirmAndDelete(slug)}>Delete</button>
      }
    ];

    const confirmAndDelete = (slug) => {
      if (window.confirm(`Are you sure to delete the district with slug "${slug}"?\nNote that all the cities and places associated with it will also be deleted!`)) deleteMutate({slug});
    }

    const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(({slug}) => deleteDistrict({slug}), {
      onSuccess: (data) => {
          message.success("District deleted successfully!");
          queryClient.invalidateQueries("get-districts");
      },
      onError: (error) => message.error(getError(error))
  })

    const { isLoading, refetch, isFetching } = useQuery(
      "get-districts",
      () => getDistricts(pagination),
      {
        onSuccess: (data) => {
          setDistricts(data.data);
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
      }
    )
  
    useEffect(() => {
      refetch();
    }, [pagination.page, pagination.pageSize]);
  return (
    <main className="main model-view room">
      <Header title="Districts" />
      <div className='main-container'>
        <div className='actions-container'>
          <Link to="/districts/add/" className='button button-regular'><MdOutlineAdd /> Add District</Link>
        </div>
        <div className='table-container'>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={districts?.results}
            loading={isLoading || isFetching}
            pagination={{
              total: districts?.count,
              current: pagination.page,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} districts`,
              onChange: (page, pageSize) => setPagination({ page, pageSize }),
            }}
          />
        </div>
      </div>
    </main>
  )
}

export default Districts