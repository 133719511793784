import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getError } from '../utility';
import { deleteMessage, getMessages } from '../apis/contactmessages';
import { message, Table } from 'antd';

const ContactMessages = () => {
    const [messages, setMessages] = useState();
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 8,
    })
    const queryClient = useQueryClient();
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Name',
        dataIndex: 'full_name',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        render: (phone) => <a href={`tel:${phone}`}>{phone}</a>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: (email) => <a href={`mailto:${email}`}>{email}</a>,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
      },
      {
        title: 'Message',
        dataIndex: 'message',
        width: "20rem",
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id) => <button type='button' className='button button-danger' onClick={() => confirmAndDelete(id)}>Delete</button>
      }
    ];
    
    const confirmAndDelete = (id) => {
      if (window.confirm(`Are you sure to delete Request Message #${id}?`)) deleteMutate({id});
    }

    const { mutate: deleteMutate, isLoading: isDeleting } = useMutation(({id}) => deleteMessage({id}), {
      onSuccess: (data) => {
          message.success("Request Message deleted successfully!");
          queryClient.invalidateQueries("get-messages");
      },
      onError: (error) => message.error(getError(error))
  })


    const { isLoading, refetch, isFetching } = useQuery(
      "get-messages",
      () => getMessages(pagination),
      {
        onSuccess: (data) => {
          setMessages(data.data);
          console.log(data);
        },
        onError: (error) => message.error(getError(error)),
      }
    )
  
    useEffect(() => {
      refetch();
    }, [pagination.page, pagination.pageSize]);
  return (
    <main className="main model-view room">
      <Header title="Contact Messages" />
      <div className='main-container'>
        <div className='table-container'>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={messages?.results}
            loading={isLoading || isFetching}
            pagination={{
              total: messages?.count,
              current: pagination.page,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} contact messages`,
              onChange: (page, pageSize) => setPagination({ page, pageSize }),
            }}
          />
        </div>
      </div>
    </main>
  )
}

export default ContactMessages