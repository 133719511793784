import { message } from "antd";
import axios from "./myaxios";
import { createContext, useContext, useState } from "react";
import { apiDomain } from "./constants";
import { determineUser } from "./utility";

let AuthContext = createContext(null);

export default function AuthProvider({ children }) {
    const [loggedUser, setLoggedUser] = useState(determineUser());
    const loginFinalise = (token, callback) => {
        localStorage.setItem("a_token", token);
        setLoggedUser(determineUser());
        callback();
      };
    const logoutUser = () => {
        localStorage.removeItem("a_token");
        setLoggedUser(determineUser());
    };
    const logoutUserFromApi = async () => {
        await axios.post(`${apiDomain}admin/logout/`)
        .then((data) => {
            message.success('Logged Out!')
        })
        .catch((error) => {
            console.log(error)
        })
        .then(()=> logoutUser())
    }
    const logoutAllFromApi = async () => {
        await axios.post(`${apiDomain}admin/logoutall/`)
        .then((data) => {
            message.success('Every of your session ended!')
            console.log(data);
        })
        .catch((error) => {
            console.log(error)
        })
        .then(()=> logoutUser())
    }
    let value = {
        loginFinalise,
        logoutUser,
        logoutUserFromApi,
        logoutAllFromApi,
        loggedUser,
    }
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
function useAuth() {
    return useContext(AuthContext);
}

export { useAuth };