import React from 'react'
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import { useAuth } from "../AuthProvider";
import { useAdminContext } from "../AdminContextProvider";
import { apiDomainOnly } from '../constants';

const Navbar = () => {
  const { logoutUserFromApi } = useAuth();
  const { user } = useAdminContext();
  const items = [
      {
        label: `${user?.user.first_name ? (user.user.first_name + " " + user?.user.last_name) : user?.user.username}`,
        key: '0',
      },
      {
        type: 'divider',
      },
      {
        label: <button onClick={logoutUserFromApi} className='button button-nav-profile'>Log Out</button>,
        key: '1',
      },
      
    ];
  return (
    <nav className="main-nav">
        <div className="logo-container">
          <div className='logo'>
            <img src='/images/only-logo.png' alt='recent air logo' />
          </div>
          <p className="title">
            Recent Air
          </p>
          <p className="sub-title">Admin Dashboard</p>
        </div>
        <div className="links-container">
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
            className="link profile"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Avatar icon={<UserOutlined />} src={`${apiDomainOnly}${user?.pic}`}>
              </Avatar>
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </nav>
  )
}

export default Navbar