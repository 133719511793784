import axios from "../myaxios";
import { apiDomain } from "../constants"

export const getProvinces = ({ page = 1, pageSize = 8 }) => {
    return axios.get(`${apiDomain}admin/provinces/?page=${page}&page_size=${pageSize}`);
}

export const getAllProvinces = () => {
    return axios.get(`${apiDomain}admin/all-provinces/`);
}

export const updateProvince = ({ slug, province }) => {
    return axios.put(`${apiDomain}admin/provinces/${slug}/`, province);
}

export const getProvinceDetails = ({slug}) => {
    return axios.get(`${apiDomain}admin/provinces/${slug}/`);
}

export const deleteProvince = ({slug}) => {
    return axios.delete(`${apiDomain}admin/provinces/${slug}/`);
}


export const createProvince = ({ province }) => {
    return axios.post(`${apiDomain}admin/provinces/`, province);
}