import { Card, message } from 'antd';
import React, { useState, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { createProvince, updateProvince, getProvinceDetails } from '../apis/provinces';
import Overlay from '../components/Overlay';
import { getError, sluggify } from '../utility';

function AddUpdateProvince() {
    const queryClient = useQueryClient();
    const { slug } = useParams();
    const is_add = (slug == null);
    const [province, setProvince] = useState({
        "name": "",
        "slug": "",
    })
    const navigate = useNavigate();

    const { mutate: createProvinceMutate, isLoading: isCreating } = useMutation(() => createProvince({province}), {
        onSuccess: (data) => {
            message.success("Province created successfully!");
            queryClient.invalidateQueries("get-provinces");
            navigate("/provinces/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })

    const { mutate: updateProvinceMutate, isLoading: isUpdating } = useMutation(() => updateProvince({slug, province}), {
        onSuccess: (data) => {
            message.success("Province updated successfully!");
            queryClient.invalidateQueries("get-provinces");
            navigate("/provinces/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })

    const checkAndCreate = async (e) => {
        e.preventDefault();
        if (!(province.name && province.slug)) {
            return message.error("Please provide all the required fields!");
        }
        if (is_add) createProvinceMutate();
        else updateProvinceMutate();
    };

    const { mutate: getProvinceDetailsMutate, isLoading: isGettingProvinceDetails } = useMutation(() => getProvinceDetails({slug}), {
        onSuccess: (data) => {
            setProvince(data.data.data);
        },
        onError: (error) => message.error(getError(error))
    })

    useEffect(() => {
        if (!is_add) getProvinceDetailsMutate({slug});
      }, [slug])
        
  return (
    <>
        <Overlay to="/provinces/" extraClass="" />
        <div className='pop-model add-province-model'>
            <h2 className='title'>{is_add ? "Add Province" : "Update Province"}</h2>
            <Card loading={isCreating || isUpdating || isGettingProvinceDetails} className='container'>
                <form onSubmit={checkAndCreate}>
                    <div className='input-container'>
                        <label htmlFor="name">Province Name <span className='asterik'>*</span></label>
                        <input type={"text"} placeholder="Bagmati" id="name" value={province.name} onChange={(e) => setProvince({...province, name: e.target.value, slug: sluggify(e.target.value)})} />
                    </div>
                    <div className='input-container'>
                        <label htmlFor="slug">Province Slug <span className='asterik'>*</span></label>
                        <input type={"text"} placeholder="bagmati" id="slug" value={province.slug} onChange={(e) => setProvince({...province, slug: e.target.value})} />
                    </div>
                    <div className='button-container'>
                        <button className='button button-primary' type='submit'>{is_add ? "Create" : "Update"}</button>
                    </div>
                </form>
            </Card>
        </div>
    </>
  )
}

export default AddUpdateProvince