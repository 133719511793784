import { Card, message, Select } from 'antd';
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { createClient, getClientDetails, updateClientDetails } from '../apis/clients';
import Overlay from '../components/Overlay';
import { getError } from '../utility';
import { getAllPlaces } from '../apis/places';

function AddUpdateUser() {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const [places, setPlaces] = useState([]);
    const [user, setUser] = useState({
        name: "",
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        alloted: [],
        allow_all: false,
        suspended: false,
        api_key: "",
        phone: "",
    })
    const is_add = (id == null);
    const navigate = useNavigate();

    const {isLoading} = useQuery(
    "get-all-places",
    getAllPlaces,
    {
        onSuccess: (data) => {console.log(data);setPlaces(data.data.data)},
        onError: (error) => message.error(getError(error, "Failed to get all places")),
    });

    const { mutate: createUserMutate, isLoading: isCreating } = useMutation(() => createClient({client: user}), {
        onSuccess: (data) => {
            message.success("User created successfully!");
            queryClient.invalidateQueries("get-clients");
            navigate("/users/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })

    const { mutate: updateUserMutate, isLoading: isUpdating } = useMutation(() => updateClientDetails({id, client: user}), {
        onSuccess: (data) => {
            message.success("User updated successfully!");
            queryClient.invalidateQueries("get-clients");
            navigate("/users/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })

    const checkAndProceed = async (e) => {
        e.preventDefault();
        console.log({user});
        if (!(user.first_name && user.last_name && user.email)) {
            return message.error("Please provide all the required fields!");
        }
        if (is_add) {
            createUserMutate();
        } else {
            updateUserMutate();
        }
    }

    const { mutate: getClientDetailsMutate, isLoading: isGettingClientDetails } = useMutation(({id}) => getClientDetails({id}), {
        onSuccess: (data) => {
            const temp_data = data.data.data;
            const temp_user = temp_data.user;
            setUser({
                name: temp_data.organization,
                first_name: temp_user.first_name,
                last_name: temp_user.last_name,
                username: temp_user.username,
                email: temp_user.email,
                allow_all: temp_data.allow_all,
                alloted: temp_data.alloted.map(place => place.id),
                suspended: temp_data.suspended,
                api_key: temp_data.api_key,
                phone: temp_data.phone,
            });
        },
        onError: (error) => message.error(getError(error))
    })

    useEffect(() => {
      if (!is_add) getClientDetailsMutate({id});
    }, [id])
    
        
  return (
    <>
        <Overlay to="/users/" extraClass="" />
        <div className='pop-model add-user-model'>
            <h2 className='title'>{is_add ? "Add User" : "Update User"}</h2>
            <Card loading={isLoading || isGettingClientDetails || isCreating || isUpdating} className='container'>
                <form onSubmit={checkAndProceed}>
                    <div className='input-container'>
                        <label htmlFor="organization_name">Organization Name</label>
                        <input type={"text"} placeholder="Kathmandu Metro." id="organization_name" value={user.name} onChange={(e) => setUser({...user, name: e.target.value})} />
                    </div>
                    <div className='sector'>
                        <span className='title'>Contact Person <span className='asterik'>*</span></span>
                        <div className='inputs-container'>
                            <div className='input-container small'>
                                <label htmlFor="first_name">First Name <span className='asterik'>*</span></label>
                                <input type={"text"} placeholder="Balendra" id="first_name" value={user.first_name} onChange={(e) => setUser({...user, first_name: e.target.value})} />
                            </div>
                            <div className='input-container small'>
                                <label htmlFor="last_name">Last Name <span className='asterik'>*</span></label>
                                <input type={"text"} placeholder="Shah" id="last_name" value={user.last_name} onChange={(e) => setUser({...user, last_name: e.target.value})} />
                            </div>
                        </div>
                        <div className='input-container small'>
                            <label htmlFor="username">Username </label>
                            <input type={"text"} placeholder="balen.shah" id="username" value={user.username} onChange={(e) => setUser({...user, username: e.target.value})} />
                        </div>
                    </div>
                    <div className='inputs-container'>
                        <div className='input-container'>
                            <label htmlFor="email">Email <span className='asterik'>*</span></label>
                            <input type={"email"} placeholder="admin@kathmandu.gov.np or balen@gmail.com" id="email" value={user.email} onChange={(e) => setUser({...user, email: e.target.value})} />
                        </div>
                        <div className='input-container'>
                            <label htmlFor="phone">Phone <span className='asterik'>*</span></label>
                            <input type={"phone"} placeholder="9800000000" id="phone" value={user.phone} onChange={(e) => setUser({...user, phone: e.target.value})} />
                        </div>
                    </div>
                    <div className='input-container'>
                        <label htmlFor="places">Alloted Places </label>
                        <Select
                        id="places"
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Select any places"
                        defaultValue={user.alloted}
                        onChange={(value) => setUser({...user, alloted: value})}
                        options={places.map(place => ({label: `${place.name}/${place.city.district.name}`, value: place.id}))}
                        />
                    </div>
                    <div className='input-container checkbox'>
                        <input type={"checkbox"} name="allow_all" id="allow_all" checked={user.allow_all} onChange={(e) => setUser({...user, allow_all: e.target.checked})} /><label htmlFor="allow_all">Allow All</label>
                    </div>
                    <div className='button-container'>
                        {!is_add &&
                        <div className='update-options'>
                            <small className='input-container'>
                                <label htmlFor="api_key">API Key </label>
                                    <input type={"text"} placeholder="Eg.: kJ45HgTui5" id="api_key" value={user.api_key} onChange={(e) => setUser({...user, api_key: e.target.value})} />
                            </small>
                            <div className='input-container checkbox'>
                                <input type={"checkbox"} name="suspended" id="suspended" checked={user.suspended} onChange={(e) => setUser({...user, suspended: e.target.checked})} /><label htmlFor="suspended">Suspended</label>
                            </div>
                        </div>
                        }
                        <button className='button button-primary' type='submit'>{is_add ? "Create" : "Update"}</button>
                    </div>
                </form>
            </Card>
        </div>
    </>
  )
}

export default AddUpdateUser