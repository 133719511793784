import React, { useState } from 'react'
import "../css/login.scss";
import {Spin, message} from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { logInAdmin } from '../apis/auth';
import { getError } from "../utility";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function Login() {
    const [form, setForm] = useState({
        username: '',
        password: '',
    })
    const [inputType, setInputType] = useState("password");
    const {loginFinalise} = useAuth();
    let location = useLocation();
    let navigate = useNavigate();

    let from = location.state?.from?.pathname || "/";

    const [loadingText, setLoadingText] = useState('');
    const verifyAndLogin = async (e) => {
        e.preventDefault();
        setLoadingText('Logging in...');
        if ((!form.username) || (!form.password)) {
            setLoadingText('');
            return message.error("Provide your username and password");
        }
        await logInAdmin({user : form})
        .then((data) => {
            setLoadingText('');
            loginFinalise(data.data.token, () => {
                navigate(from, { replace: true });
              });
        })
        .catch((error) => {
            console.log(error);
            setLoadingText('');
            message.error(getError(error));
        })
    };
    const switchInput = (e) => {
        if (inputType === "password")
        setInputType("text");
        else setInputType("password");
    }
  return (
    <Spin tip={loadingText} size='large' spinning={loadingText?true:false}>
        <div className='Login'>
            <main className='main login'>
                <div className='login-container'>
                    <span className='tag'><MdOutlineAdminPanelSettings /> Admin</span>
                    <div className='image-container'>
                        <div className='logo-container'>
                            <img src='/images/only-logo.png' alt='' />
                        </div>
                        <div className='name-container'>
                            <h1 className='title'>Recent Air | Dashboard</h1>
                        </div>
                    </div>
                    <div className='welcome-back'>
                        <h2 className='title'>Welcome Back!</h2>
                        <p className='text'>Login with the <span>admin account</span> to access</p>
                    </div>
                    <div className='login-form'>
                        <form onSubmit={verifyAndLogin}>
                            <div className='input-container'>
                                <label htmlFor='username'>Username: </label>
                                <input type={"text"} id="username" value={form.username} onChange={(e) => setForm({...form, username: e.target.value})} required />
                            </div>
                            <div className='input-container' style={{
                                position: "relative",
                            }}>
                                <label htmlFor='password'>Password: </label>
                                <input type={inputType} id="password" value={form.password} onChange={(e) => setForm({...form, password: e.target.value})} required />
                                {form.password.length > 0 && <button type='button' className='button button-simple' onClick={switchInput}>{inputType === "password" ? <AiFillEye /> : <AiFillEyeInvisible />}</button>}
                            </div>
                            <div className='button-container'>
                                <button type='submit'>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    </Spin>
  )
}

export default Login