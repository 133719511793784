export const getError = (error, defaultMessage = "Some unknown error occured") => {
    return (error.response?.data?.message || Object.values(error.response?.data)[0] || error.message || defaultMessage);
}

export const determineUser = () => {
    const accessToken = localStorage.getItem("a_token");
    if (accessToken) {
        return true;
    } else return false;
}
export const sluggify = (text) => {
    return text.toLowerCase().replaceAll(" ", "-").replaceAll(".", "");
}