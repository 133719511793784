import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import {Breadcrumb} from "antd";

function Header({title}) {
    const location = useLocation();
    const breadcrumbNameMap = {
        '/provinces': 'Provinces',
        '/provinces/add': 'Add',
        '/districts': 'Districts',
        '/districts/add': 'Add',
        '/sensors': 'Sensors',
        '/sensors/add': 'Add',
        '/local-levels': 'Local Levels',
        '/local-levels/add': 'Add',
        '/users': 'Users',
        '/users/add': 'Add',
        '/activities': 'Activities',
        '/messages': 'Messages',
        '/customers/add': 'Add',
        '/banners': 'Banners',
        '/banners/add': 'Add',
      };
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const breadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      return (
        <Breadcrumb.Item key={url}>
            <Link to={url}>{breadcrumbNameMap[url] || "Particulars"}</Link>
        </Breadcrumb.Item>
      )
    })
  return (
      <header className='header'>
        <Breadcrumb separator=">">
          {breadcrumbItems.length !== 0 ?
          <Breadcrumb.Item>
            <Link to={'/'}>Home</Link>
          </Breadcrumb.Item>
          : null }
          {breadcrumbItems}
        </Breadcrumb>
        <h1 className='title'>{title}</h1>
    </header>
  )
}

export default Header