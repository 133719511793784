import { Card, message, Upload } from 'antd';
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { createPlace, getPlaceDetails, updatePlace } from '../apis/places';
import Overlay from '../components/Overlay';
import { getError, sluggify } from '../utility';
import { getCities } from '../apis/cities';
import { apiDomainOnly } from '../constants';

function AddUpdatePlaces() {
    const queryClient = useQueryClient();
    const { slug } = useParams();
    const is_add = (slug == null);
    const [initialImage, setInitialImage] = useState("");
    const [cities, setCities] = useState([]);
    const [place, setPlace] = useState({
        "off": false,
        "name": "",
        "slug": "",
        "image": "",
        "city": "",
        "lat": "",
        "lon": "",
    })
    const [fileList, setFileList] = useState([]);
    const navigate = useNavigate();

    const {isLoading} = useQuery(
    "get-cities",
    getCities,
    {
        onSuccess: (data) => setCities(data.data.results),
        onError: (error) => message.error(getError(error, "Failed to get cities")),
    });

    const { mutate: createPlaceMutate, isLoading: isCreating } = useMutation(({place}) => createPlace({place}), {
        onSuccess: (data) => {
            message.success("Sensor created successfully!");
            queryClient.invalidateQueries("get-places");
            navigate("/sensors/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })
    const { mutate: updatePlaceMutate, isLoading: isUpdating } = useMutation(({slug, place}) => updatePlace({slug, place}), {
        onSuccess: (data) => {
            message.success("Sensor updated successfully!");
            queryClient.invalidateQueries("get-places");
            navigate("/sensors/", {replace: true});
        },
        onError: (error) => message.error(getError(error))
    })
    const { mutate: getPlaceDetailsMutate, isLoading: isGetting } = useMutation(({slug}) => getPlaceDetails({slug}), {
        onSuccess: (data) => {
            setPlace(data.data.data);
            setInitialImage(data.data.data.image);
        },
        onError: (error) => message.error(getError(error))
    })

    const checkAndCreate = async (e) => {
        e.preventDefault();
        if (!(place.name && place.city && place.lat && place.lon)) {
            return message.error("Please provide all the required fields!");
        }
        const formData = new FormData();
        Object.keys(place).forEach(key => {
            if (key != "image") formData.append(key, place[key]);
        });
        if (fileList.length != 0) {
            console.log(fileList[0]);
            if (fileList[0].name != initialImage) {
                formData.append("image", fileList[0].originFileObj);
            }
        }
        
        if (is_add) createPlaceMutate({place: formData});
        else updatePlaceMutate({slug, place: formData});
    };
    
      const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
      };
      const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
      };
    
      useEffect(() => {
        if (!is_add) getPlaceDetailsMutate({slug});
      }, [slug])
        
  return (
    <>
        <Overlay to="/sensors/" extraClass="" />
        <div className='pop-model add-place-model'>
            <h2 className='title'>{is_add ? "Add Sensor" : "Edit Sensor"}</h2>
            <Card loading={isLoading || isCreating || isUpdating || isGetting} className='container'>
                <form onSubmit={checkAndCreate}>
                    <div className='inputs-container'>
                        <div className='input-container'>
                            <label htmlFor="name">Name <span className='asterik'>*</span></label>
                            <input type={"text"} placeholder="Maiti Ghar S2" id="name" value={place.name} disabled={!is_add} onChange={(e) => setPlace({...place, name: e.target.value, slug: sluggify(e.target.value)})} />
                        </div>
                        <div className='input-container'>
                            <label htmlFor="slug">Slug <span className='asterik'>*</span></label>
                            <input type={"text"} placeholder="maiti-ghar" id="slug" value={place.slug} disabled={!is_add} onChange={(e) => setPlace({...place, slug: e.target.value})} />
                        </div>
                    </div>
                    <Upload
                        action=""
                        beforeUpload={()=> false}
                        customRequest={()=>{}}
                        listType="picture"
                        fileList={place?.image ? [{
                            uid: '-1',
                            name: `${place?.image?.split("/")?.slice(-1)[0]}`,
                            status: 'done',
                            url: `${apiDomainOnly}${place.image}`,
                        }] : fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                    >
                        {fileList.length < 1 && '+ Upload'}
                    </Upload>
                    <div className='inputs-container'>
                        <div className='input-container'>
                            <label htmlFor="lat">Lat <span className='asterik'>*</span></label>
                            <input type={"number"} placeholder="27.6920" id="lat" value={place.lat} onChange={(e) => setPlace({...place, lat: e.target.value})} />
                        </div>
                        <div className='input-container'>
                            <label htmlFor="lon">Lon <span className='asterik'>*</span></label>
                            <input type={"number"} placeholder="85.3230" id="lon" value={place.lon} onChange={(e) => setPlace({...place, lon: e.target.value})} />
                        </div>
                    </div>
                    <div className='input-container'>
                        <label htmlFor="category">City <span className='asterik'>*</span></label>
                        <select id="city" onChange={(e) => setPlace({...place, city: e.target.value})} value={place.city}>
                            <option value=''>Select a city</option>
                            {cities?.map((city) => {
                                return <option value={city.slug} key={city.slug}>{city.name}</option>
                            })}
                        </select>
                    </div>
                    <div className='input-container checkbox'>
                        <input type={"checkbox"} name="off" id="off" checked={place.off} onChange={(e) => setPlace({...place, off: e.target.checked})} /><label htmlFor="off">Off</label>
                    </div>
                    <div className='button-container'>
                        <button className='button button-primary' type='submit'>{is_add ? "Create" : "Update"}</button>
                    </div>
                </form>
            </Card>
        </div>
    </>
  )
}

export default AddUpdatePlaces