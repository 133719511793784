import React from 'react'
import { MdOutlineAdd } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Header from '../components/Header'

const Activities = () => {
  return (
    <main className="main model-view room">
        <Header title="Activities" />
        <div className='main-container'>
            {/* <div className='actions-container'>
                <Link to="/places/add/" className='button button-regular'><MdOutlineAdd /> Add Users</Link>
            </div> */}
            <div className='table-container'></div>
        </div>
    </main>
  )
}

export default Activities